import React from 'react';

function Buy() {
  return (
    <div>
      <h1>Buy Memecoin</h1>
      <p>Here's how you can buy Memecoin safely.</p>
    </div>
  );
}

export default Buy;
