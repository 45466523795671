import React from 'react';

function About() {
  return (
    <div>
      <h1>About Memecoin</h1>
      <p>Learn more about our mission and team.</p>
    </div>
  );
}

export default About;